import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'expenses'

type optionsExpenses = {
  _firmId: string
  _id: string
}

const getExpensesByProject = (axios: IFactoryFunctionRequest<optionsExpenses, undefined>) => (projectId: string, data: any) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${projectId}`,
    data,
  })
}

const initExpenses = (axios: IFactoryFunctionRequest<any, any>) => (data) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/initExpenses`,
    data,
  })
}

const patchExpense = (axios: IFactoryFunctionRequest<any, any>) => (expenseId: string, data: { expense: {merchant?: string; amount?: number; vat?: number; accountingDate?: string; documents?: any[]; projectId?: string} }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${expenseId}`,
    data,
  })
}

const getExpensesList = (axios: IFactoryFunctionRequest<any, any>) => ({ projectId }: { projectId?: string[] }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    query: { projectId },
  })
}
const deleteExpenses = (axios: IFactoryFunctionRequest<any, any>) => ({ expenseIds }: { expenseIds?: string[] }) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    data: { expenseIds },
  })
}

export default (axios: AxiosInstance) => ({
  getExpensesByProject: getExpensesByProject(useAxios(axios)),
  initExpenses: initExpenses(useAxios(axios)),
  getExpensesList: getExpensesList(useAxios(axios)),
  patchExpense: patchExpense(useAxios(axios)),
  deleteExpenses: deleteExpenses(useAxios(axios)),
})
