import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'timesheets'

const getTimesheetsByProject = (axios: IFactoryFunctionRequest<any, any>) => (projectId: string): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/${projectId}`,
  })
}

const getTimesheetsInterval = (axios: IFactoryFunctionRequest<any, any>) => ({ startDate, endDate, resources }: { startDate: Date; endDate: Date; resources: sting[] }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/interval/${startDate}/${endDate}`,
    data: { resources },
  })
}

const timesheetProjectMonitoring = (axios: IFactoryFunctionRequest<any, any>) => ({ projectId }: { projectId: string }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/engagement/${projectId}/monitoring`,
  })
}
const sendReminder = (axios: IFactoryFunctionRequest<any, any>) => ({ timesheetIds = [], timesheetsNotCreated = [] }: { timesheetIds: string[]; timesheetsNotCreated: any[] }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/sendReminder`,
    data: { timesheetIds, timesheetsNotCreated },
  })
}
const updateBooking = (axios: IFactoryFunctionRequest<any, any>) => ({ timesheetId, booking }: { timesheetId: string;booking: { _id: string } }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${timesheetId}/updateBooking/${booking._id}`,
    data: booking,
  })
}

const getTimesheetList = (axios: IFactoryFunctionRequest<any, any>) => (query: { resourceIds: string[]; month; year; search: string; shouldFetchAll: boolean }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    query,
  })
}

const updateBookingTimesheet = (axios: IFactoryFunctionRequest<any, any>) => ({ timesheetId, data }: { timesheetId: string;data: { _id: string } }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/updateBookingTimesheet/${timesheetId}/${data._id}`,
    data,
  })
}

const updateTimesheetDetails = (axios: IFactoryFunctionRequest<any, any>) => ({ timesheetId, details }: { timesheetId: string;details: any[] }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/updateDetails/${timesheetId}`,
    data: {details},
  })
}
const createNewTimesheet = (axios: IFactoryFunctionRequest<any, any>) => ({ data }: { data: any }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    data,
  })
}

const deleteBooking = (axios: IFactoryFunctionRequest<any, any>) => ({ timesheetId, bookingId }: { timesheetId: string; bookingId: string }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${timesheetId}/deleteBooking/${bookingId}`,
  })
}

const updateTimesheetBookingDays = (axios: IFactoryFunctionRequest<any, any>) => ({ timesheetId, bookingId, data }: { timesheetId: string; bookingId: string; data }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${timesheetId}/${bookingId}/days`,
    data,
  })
}

const getProjectsWaitingForTimesheetValidations = (axios: IFactoryFunctionRequest<any, any>) => (): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/waitingForValidation`,
  })
}

export default (axios: AxiosInstance) => ({
  getTimesheetsByProject: getTimesheetsByProject(useAxios(axios)),
  getTimesheetsInterval: getTimesheetsInterval(useAxios(axios)),
  timesheetProjectMonitoring: timesheetProjectMonitoring(useAxios(axios)),
  updateBooking: updateBooking(useAxios(axios)),
  sendReminder: sendReminder(useAxios(axios)),
  getTimesheetList: getTimesheetList(useAxios(axios)),
  createNewTimesheet: createNewTimesheet(useAxios(axios)),
  deleteBooking: deleteBooking(useAxios(axios)),
  updateTimesheetBookingDays: updateTimesheetBookingDays(useAxios(axios)),
  updateBookingTimesheet: updateBookingTimesheet(useAxios(axios)),
  updateTimesheetDetails: updateTimesheetDetails(useAxios(axios)),
  getProjectsWaitingForTimesheetValidations: getProjectsWaitingForTimesheetValidations(useAxios(axios)),
})
