import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IProjectcategory } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'engagementCategorys'

const getAll = (axios: IFactoryFunctionRequest<any, any>) => ({ firmId }: { firmId: string }): IFactoryResponse<Array<IProjectcategory>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/${firmId}`,
  })
}

const getFilter = (axios: IFactoryFunctionRequest<Array<Pick<IProjectcategory, '_id' | 'display'>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/filter`,
    query: options,
  })
}

const paginate = (axios: IFactoryFunctionRequest<Array<Pick<IProjectcategory, '_id' | 'display'>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: options,
  })
}

const createOne = (axios: IFactoryFunctionRequest<Array<Pick<IProjectcategory, '_id' | 'display'>>, any>) => (data: { display: string }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}`,
    data,
  })
}
export default (axios: AxiosInstance) => ({
  getAll: getAll(useAxios(axios)),
  getFilter: getFilter(useAxios(axios)),
  createOne: createOne(useAxios(axios)),
  paginate: paginate(useAxios(axios)),
})
