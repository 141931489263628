import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'expenseCategorys'


const filter = (axios: IFactoryFunctionRequest<any, any>) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query,
  })
}

const partialUpdate = (axios: IFactoryFunctionRequest<any, any>) => (categoryId: string, data: {display: string}) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${categoryId}`,
    data,
  })
}

const deleteExpenseCategory = (axios: IFactoryFunctionRequest<any, any>) => (categoryId: string) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${categoryId}`,
  })
}

const create = (axios: IFactoryFunctionRequest<any,any>) => (data) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    data,
  })
}


export default (axios: AxiosInstance) => ({
  filter: filter(useAxios(axios)),
  partialUpdate: partialUpdate(useAxios(axios)),
  deleteExpenseCategory: deleteExpenseCategory(useAxios(axios)),
  create: create(useAxios(axios)),
})
