import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'employeeOnboardings'

const createEmployeeOnboarding = (axios: IFactoryFunctionRequest<any, any>) => (boardingProcessId, processId, { sendInvitation = false }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/create/${boardingProcessId}/${processId}`,
    query: { sendInvitation },
  })
}

const createHrEmployeeOnboarding = (axios: IFactoryFunctionRequest<any, any>) => (boardingProcessId, resourceId, { sendInvitation = false }): IFactoryResponse<any, any> => {
  const query = sendInvitation ? { sendInvitation } : {}
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/createHr/${boardingProcessId}/${resourceId}`,
    query,
  })
}

/***
 * Return paginated employee onboarding processes
 * @param page
 * @param limit
 * @param processId
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmployeeOnboardingProcesses = (axios: IFactoryFunctionRequest<any, any>) => ({ page, limit, processId }: { page?: number; limit?: number; processId?: string }): IFactoryResponse<any, any> => {
  // if no processId , error
  if (!processId ) return Promise.reject(new Error('Missing parameters'))
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/process/${processId}`,
    query: { page, limit },
  })
}



// // get employee and process info for hashtags" mail
// const retreiveMailHashTagsInfo = (axios: IFactoryFunctionRequest) => ({ processId }): IFactoryResponse<any, any> => {
//   return axios({
//     method: EMethod.get,
//     url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/boardingMailHashTags/${processId}`,
//   })
// }

const getCurrentResourceProcesses = (axios: IFactoryFunctionRequest) => (): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/currentResource`,
  })
}


const checkAction = (axios: IFactoryFunctionRequest<any, any>) => (processId, actionId): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/check/${processId}/action/${actionId}`,
  })
}


// const sendMailResponse = await employeeOnboardingApi.sendMailAction({processId: props.employeeOnboarding._id, actionId:action.value._id, subject: template.value.headline, html: template.value.content, includeSignature: includeSignature.value, attachments: attachments.value }).promise
const sendMailAction = (axios: IFactoryFunctionRequest<any, any>) => ({ processId, actionId, subject, html, includeSignature, attachments }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/sendMail/${processId}/action/${actionId}`,
    data: { subject, html, includeSignature, attachments },
  })
}


const postDocument = (axios: IFactoryFunctionRequest<any, any>) => (processId, actionId, document): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/document/${processId}/${actionId}`,
    data: document,
  })
}

const deleteDocument = (axios: IFactoryFunctionRequest<any, any>) => (processId, actionId): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/document/${processId}/${actionId}`
  })
}

// TODO test
const archiveProcess = (axios: IFactoryFunctionRequest<any, any>) => (processId): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/archive/${processId}`,
  })
}
// set process.isDeleted = true
const deleteProcess = (axios: IFactoryFunctionRequest<any, any>) => (processId): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/delete/${processId}`,
  })
}

const retreiveMailHashTagsInfo = (axios: IFactoryFunctionRequest<any, any>) => ({ processId, actionId }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/mailHashTags/${processId}/action/${actionId}`,
  })
}

const getResourceProcesses = (axios: IFactoryFunctionRequest<any, any>) => ({ resourceId }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/resource/${resourceId}`,
  })
}




export default (axios: AxiosInstance) => ({
  createEmployeeOnboarding: createEmployeeOnboarding(useAxios(axios)),
  createHrEmployeeOnboarding: createHrEmployeeOnboarding(useAxios(axios)),
  getEmployeeOnboardingProcesses: getEmployeeOnboardingProcesses(useAxios(axios)),
  getCurrentResourceProcesses: getCurrentResourceProcesses(useAxios(axios)),
  retreiveMailHashTagsInfo: retreiveMailHashTagsInfo(useAxios(axios)),
  checkAction: checkAction(useAxios(axios)),
  sendMailAction: sendMailAction(useAxios(axios)),
  postDocument: postDocument(useAxios(axios)),
  deleteDocument: deleteDocument(useAxios(axios)),
  archiveProcess: archiveProcess(useAxios(axios)),
  deleteProcess: deleteProcess(useAxios(axios)),
  getResourceProcesses: getResourceProcesses(useAxios(axios)),
})
