<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { TransitionPresets, useIntervalFn, useTransition } from '@vueuse/core'
import { animate } from 'motion'
import type { TColors } from '@/services/application/interfaces'
import { bgColors } from '@/services/application/constantes'
import IconClose from '~icons/ic/round-close'
import MaterialSymbolsNotificationImportantOutlineRounded from '~icons/material-symbols/notification-important-outline-rounded'
const stopAnimation = ref(false)

const props = withDefaults(defineProps<{
  alert: IAlert
  shadow?: boolean
  size?: keyof typeof sizes
}>(), {
  shadow: false,
  size: 'large',
})

const emits = defineEmits<{
  'close': [alert: IAlert]
}>()

const sizes = {
  small: 'px-3 py-2 gap-2',
  normal: 'px-5 py-3 gap-3',
  large: 'px-8 py-5 gap-5',
}

const sizesTextTitle = {
  small: 'text-sm',
  normal: 'text-base',
  large: 'text-lg',
}

type IAlert = {
  id?: string
  title?: string
  message?: string
  color: keyof typeof bgColors
  duration: number
  includeIcon?: boolean
  version?: number
}

const progression = ref(0)


const output = useTransition(progression, {
  duration: props.alert.duration,
  transition: TransitionPresets.easeInOutCubic,
  disabled: stopAnimation
})


const progressPercent = computed(() => {
  const currentProgress = Math.min(output.value * 100 / props.alert.duration, 100)
  if(currentProgress >= 100) close()
  return currentProgress
})

const close = () => {
  emits('close', props.alert)
}

progression.value = props.alert.duration
</script>

<template>
  <div class="rounded-xl relative transition-all flex items-center overflow-hidden" :class="[bgColors[alert.color], { 'shadow-xl': shadow }, sizes[size]]">
    <slot name="icon" :alert="alert">
      <MaterialSymbolsNotificationImportantOutlineRounded />
    </slot>
    <slot name="content" :alert="alert">
      <div class="">
        <slot name="header" :alert="alert">
          <header class="font-bold" :class="[sizesTextTitle[size]]">
            <slot name="header-content" :alert="alert">
              <h4>{{ alert.title }}</h4>
            </slot>
          </header>
        </slot>
        <slot name="content" :alert="alert">
          <div class="vs-notification__content__text">
            <slot :alert="alert">
              <p class="">
                {{ alert.message }}
              </p>
            </slot>
          </div>
        </slot>
      </div>
    </slot>
    <slot name="close-button" :alert="alert" :close="close">
      <button class="absolute -top-1.5 -right-1.5 -translate-x-1/2 translate-y-1/2" @click="close">
        <slot name="icon-close">
          <IconClose :class="[sizesTextTitle[size]]" />
        </slot>
      </button>
    </slot>
    <slot v-if="progression" name="progress" :alert="alert">
      <div :style="{ right: `${100 - progressPercent}%` }" class="absolute h-1 left-0 bottom-0 bg-gray-50/50" />
    </slot>
  </div>
</template>

<style scoped>
</style>
