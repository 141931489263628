import type { RouteRecordRaw } from 'vue-router'
import { guardHrAlumniAccess, guardHrAccess, guardHrCreateResource, guardHrStatsAccess, guardResourceProfile, guardSettingsAccess } from '@/config/router/navigationsGuards/guardHr'

const MODULE_NAME = 'HR'
const MODULE_ID = 1

const hrRoutes: Array<RouteRecordRaw> = [
  {
    path: '/hr',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'HrBase',
        component: () => import('@/views/hrBase/HrBase.vue'),
        beforeEnter: [guardHrAccess],
        meta: {
          titleTranslationKey: 'pageTitle.directory',
        },
      },
      {
        path: 'alumni',
        name: 'HrAlumni',
        component: () => import('@/views/hrBase/AlumniBase.vue'),
        beforeEnter: [guardHrAlumniAccess],
        meta: {
          titleTranslationKey: 'pageTitle.alumniDirectory',
        },
      },
      {
        path: 'new',
        name: 'HrCreate',
        component: () => import('@/views/hrBase/HrCreate.vue'),
        beforeEnter: [guardHrAccess, guardHrCreateResource],
        meta: {
          titleTranslationKey: 'pageTitle.hrCreate',
        },
      },
      {
        path: 'onboarding',
        name: 'Onboarding',
        component: () => import('@/views/hrBase/HrOnboarding.vue'),
        beforeEnter: [guardHrAccess],
        meta: {
          titleTranslationKey: 'pageTitle.onboarding',
        },
      },
      {
        path: ':resourceId',
        name: 'EmployeeProfile',
        component: () => import('@/views/hrBase/EmployeeProfile.vue'),
        meta: {
        },
        beforeEnter: [guardHrAccess, guardResourceProfile],
      },
      {
        path: '/stats',
        name: 'HrStats',
        component: () => import('@/views/hrBase/Stats.vue'),
        meta: {
          titleTranslationKey: 'pageTitle.hrStats',
        },
        beforeEnter: [guardHrStatsAccess],
      },
      {
        path: 'settings',
        name: 'HrSettings',
        component: () => import('@/views/hrBase/Settings.vue'),
        meta: {
          titleTranslationKey: 'pageTitle.hrSettings',
        },
        beforeEnter: [guardSettingsAccess],
      },
    ],
  },

]

export default hrRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
