<script lang="ts" setup>
import { computed, onMounted, ref, useId, useSlots, useTemplateRef } from 'vue'
import loading from '@/components/common/loading.vue'

import { bgColors,size as sizeConst } from '@/services/application/constantes'

const props = withDefaults(defineProps<{
  containerClass?: string
  inputContainerClass?: string
  autocomplete?: string
  inputType?: 'tel' | 'search' | 'text' | 'email' | 'number' | 'password' | 'file' | 'url' | 'date' | 'month' | 'week' | 'time' | 'datetime' | 'datetime-local' | 'range' | 'color' | 'month'
  label?: string
  labelFixed?: boolean
  autofocus?: boolean
  placeholder?: string
  disabled?: boolean
  errorMessage?: string
  shadow?: string
  isLoading?: boolean
  size?: keyof typeof sizeConst
  required?: boolean
  id?: string
  bg?: keyof typeof bgColors
  minMax?: { min: number, max: number } // for number input
}>(), {
  labelFixed: false,
  autofocus: false,
  inputType: 'text',
  autocomplete: '',
  disabled: false,
  placeholder: '',
  shadow: 'shadow',
  isLoading: false,
  size: 'small',
  required: false,
  id: useId(),
})
const emit = defineEmits(['leaveInput', 'contentChanged'])
const slots = useSlots()


const textValue = defineModel<string | number>()

const inputElement = useTemplateRef<HTMLInputElement>('inputElement')

const focusInput = () => {
  inputElement.value.focus()
}


const topDistance = computed(() => {
  if (props.size === 'dense')
    return '-1.2rem'
  if (props.size === 'small')
    return '-1.4rem'
  if (props.size === 'medium')
    return '-1.5rem'
  if (props.size === 'large')
    return '-1.7rem'  

  return '0.2rem'
})

</script>

<script lang="ts">
// so that we can pass attributes to the input element instead of the wrapper div
export default {
  inheritAttrs: false,
}
</script>

<template>
  <div class="flex" :class="containerClass"> 
    <slot name="prepend" />
    <div class="flex flex-col grow relative">

      <div
        class="grow group relative group border-base border bg-base rounded-lg focus focus-within:border-primary-600 focus-within:outline-none px-3 active:outline-none active:border-primary-600 flex flex-wrap"
        :class="[inputContainerClass, { 'mt-5': label }, shadow , sizeConst[size]]"
        @click="focusInput"
      >
        <span class="absolute left-2 top-1/2 -translate-y-1/2 subtitle">
          <slot name="prepend-icon" />
        </span>
        <input :id="id"
          ref="inputElement" v-bind="$attrs" v-model="textValue"
          :class="[{ filled: labelFixed || placeholder, 'cursor-not-allowed': disabled }, { 'ml-4': !!slots['prepend-icon'] }]"
          class="input appearance-none w-full outline-none bg-transparent" :placeholder="placeholder"
          :autofocus="autofocus" :autocomplete="autocomplete" :disabled="disabled" :type="inputType"
          :min="minMax?.min" :max="minMax?.max"
          @blur="$emit('leaveInput')" @input="$emit('contentChanged')"
        >

        <label
          v-if="label" 
          :for="id"
          class="label absolute leading-tighter text-gray-600 dark:text-gray-400 text-base cursor-text line-clamp-1"
          :class="[ sizeConst[size] ]"
          @click="focusInput"
        >
          {{ label }} 
          <span v-if="required" class="text-danger-400 text-sm">*</span>
        </label>
      </div>
      <transition name="shake">
        <p v-show="errorMessage" class="text-red-400 text-sm">
          {{ errorMessage }}
        </p>
      </transition>
      <span v-if="isLoading" class="absolute right-2 bottom-2 -translate-x-1/2">
        <loading />
      </span>
    </div>
    <slot name="append" />
  </div>
</template>

<style scoped>
.input {
  transition: border 0.2s ease-in-out;
}
.input:not(:placeholder-shown) ~ .label,
.input:focus ~ .label,
.input:active ~ .label,
.input.filled ~ .label {
  transition: all 0.2s ease;
  padding: 0;
}

.label {
  transition: all 0.2s ease-out;
  left: 0;
  top: v-bind(topDistance);
  padding: 0;
}
</style>
