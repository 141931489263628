import IconDashboard from '~icons/ps/dashboard'
import IconDocumentSearch from '~icons/heroicons-outline/document-search'
import IconSchool from '~icons/ic/twotone-school'
import IconChart from '~icons/ic/sharp-show-chart'
import IconNewDashboard from '~icons/ic/sharp-dashboard-customize'
import PhUsersThree from '~icons/ph/users-three'
import PhUserPlus from '~icons/ph/user-plus'
import PhUserSwitch from '~icons/ph/user-switch'
import IcOutlinePlaylistAdd from '~icons/ic/outline-playlist-add'
import IcBaselineList from '~icons/ic/baseline-list'
import MdiFormatListText from '~icons/mdi/format-list-text'
import MdiCashCheck from '~icons/mdi/cash-check'
import MdiCashClock from '~icons/mdi/cash-clock'
import MdiCashFast from '~icons/mdi/cash-fast'
import MaterialSymbolsGooglerTravel from '~icons/material-symbols/googler-travel'
import MaterialSymbolsAssignmentAddOutlineSharp from '~icons/material-symbols/assignment-add-outline-sharp'
import MaterialSymbolsAssignmentOutlineRounded from '~icons/material-symbols/assignment-outline-rounded'
import IcBaselinePersonSearch from '~icons/ic/baseline-person-search'
import MaterialSymbolsEventNoteOutlineRounded from '~icons/material-symbols/event-note-outline-rounded'
import PhChartLineUpLight from '~icons/ph/chart-line-up-light'
import MaterialSymbolsNestClockFarsightAnalogOutlineRounded from '~icons/material-symbols/nest-clock-farsight-analog-outline-rounded'
import { guardHrAlumniAccess, guardHrAccess, guardHrCreateResource, guardHrStatsAccess } from '@/config/router/navigationsGuards/guardHr'
import { guardConsumption, guardProfiles, guardUsers } from '@/config/router/navigationsGuards/guardSuperAdmin'
import { guardCashFlow, guardDashboardAccess, guardProduction, guardResource, guardSales } from '@/config/router/navigationsGuards/guardDashboard'
import { guardPlanningResource, guardProjectAccess, guardProjectCreation } from '@/config/router/navigationsGuards/guardProject'
import { guardTimesheetAccess } from '@/config/router/navigationsGuards/guardTimesheet'
import { guardCalendar, guardLeaveAccess, guardNewBulkRequest, guardNewRequest, guardRequest } from '@/config/router/navigationsGuards/guardLeave'
import { guardCreationEvaluation, guardEvaluationAccess } from '@/config/router/navigationsGuards/guardEvaluation'
import { guardExpenseAccess, guardExpenseCreation, guardExpenseSettings } from '@/config/router/navigationsGuards/guardExpense'
import { guardCandidateBase, guardCreateCandidate, guardJobBoard, guardJobBoardAdmin, guardPipe, guardRecruitmentAccess, guardRecruitmentStatsAccess, guardScreening, guardSettingsAccess } from '@/config/router/navigationsGuards/guardRecruitment'
import MaterialSymbolsEditDocumentOutlineRounded from '~icons/material-symbols/edit-document-outline-rounded'
import SolarCaseOutline from '~icons/solar/case-outline'
import SolarPaintRollerLineDuotone from '~icons/solar/paint-roller-line-duotone'
import PhGearSixThin from '~icons/ph/gear-six-thin'
import MdiTimelineClockOutline from '~icons/mdi/timeline-clock-outline'
import SolarUserPlusLineDuotone from '~icons/solar/user-plus-line-duotone'
import SolarCalendarLineDuotone from '~icons/solar/calendar-line-duotone'
import SolarDocumentsLineDuotone from '~icons/solar/documents-line-duotone'
import PhKanbanLight from '~icons/ph/kanban-light'
import SolarSuitcaseBroken from '~icons/solar/suitcase-broken'
import SolarDatabaseLineDuotone from '~icons/solar/database-line-duotone'
import SolarBillListLineDuotone from '~icons/solar/bill-list-line-duotone'
import SolarDocumentAddLineDuotone from '~icons/solar/document-add-line-duotone'
import SolarChartOutline from '~icons/solar/chart-outline'
import SolarWalletMoneyOutline from '~icons/solar/wallet-money-outline'
import PhUserList from '~icons/ph/user-list'
import SolarMoneyBagLineDuotone from '~icons/solar/money-bag-line-duotone'
import SolarChartLineDuotone from '~icons/solar/chart-line-duotone'
import SolarDocumentTextLineDuotone from '~icons/solar/document-text-line-duotone'
import SolarDocumentMedicineBroken from '~icons/solar/document-medicine-broken'
import SolarCalculatorMinimalisticBroken from '~icons/solar/calculator-minimalistic-broken'
import PhAirplaneTakeoffLight from '~icons/ph/airplane-takeoff-light'
import PhChartDonutDuotone from '~icons/ph/chart-donut-duotone'
import PhUserSoundDuotone from '~icons/ph/user-sound-duotone'
import SolarSquareAcademicCap2LineDuotone from '~icons/solar/square-academic-cap-2-line-duotone'

export const bgColors = {
  'primary': 'text-white bg-primary-500',
  'primary-op': 'bg-primary-400/20 text-primary-700 dark:bg-primary-500/20 dark:text-primary-400 border dark:border-primary-700/30 border-primary-400/30',
  'success': 'text-white bg-success-500',
  'success-op': 'bg-success-400/20 text-success-700 dark:bg-success-500/20 dark:text-success-500 border dark:border-success-700/30 border-success-400/30',
  'warning': 'text-white bg-warning-500',
  'warning-op': 'bg-warning-400/20 text-warning-600 dark:bg-warning-500/20 dark:text-warning-500 border border-warning-700/30',
  'base': 'bg-base border border-base',
  'base-varient': 'bg-fade border border-gray-500/30',
  'error': 'text-white bg-red-700',
  'error-op': 'bg-danger-400/20 text-danger-500 dark:bg-danger-500/20 dark:text-danger-400 border border-danger-700/30',
  'errorLight': 'text-white bg-red-400',
  'errorLight-op': 'bg-danger-300/20 text-danger-300 dark:bg-danger-300/20 dark:text-danger-300 border border-danger-700/30',
  'loading': 'opacity-70',
  'disabled': 'text-white text-shadow bg-gray-500 dark:bg-gray-600',
  'disabled-op': 'text-gray-800/70 dark:text-gray-300 bg-gray-600/20 dark:bg-gray-500/20 border border-gray-500/30',
  'disabledLight': 'bg-fade',
  'transparent': 'bg-transparent',
}

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export enum labelColors {
  primary = 'bg-primary-400/20 text-primary-700 dark:bg-primary-500/20 dark:text-primary-500',
  'primary-plain' = 'bg-primary-400 text-gray-100 dark:bg-primary-500 dark:text-primary-100',
  success = 'bg-success-400/20 text-success-700 dark:bg-success-500/20 dark:text-success-500',
  'success-plain' = 'bg-success-400 text-success-800 dark:bg-success-500 dark:text-success-100',
  warning = 'bg-warning-400/20 text-warning-600 dark:bg-warning-500/20 dark:text-warning-500',
  'warning-plain' = 'bg-warning-500 text-warning-800 dark:bg-warning-500 dark:text-warning-100',
  base = 'bg-base border-base border',
  'base-varient' = 'bg-fade',
  error = 'bg-danger-400/20 text-danger-500 dark:bg-danger-500/20 dark:text-danger-400',
  'error-plain' = 'bg-danger-600 text-danger-100 dark:bg-danger-700 dark:text-danger-100',
  errorLight = 'bg-danger-300/20 text-danger-300 dark:bg-danger-300/20 dark:text-danger-300',
  loading = 'opacity-70',
  disabled = 'text-gray-50 text-shadow bg-gray-500 base-border',
  disabledLight = 'bg-fade',
  transparent = 'bg-transparent',
}
// error = 'bg-danger-500/80 text-danger-50 dark:bg-danger-500/20 dark:text-danger-500',

export enum size {
  dense = 'h-6 text-xs px-2 py-1',
  small = 'h-8 text-sm px-2 py-1',
  medium = 'h-10 text-base px-2 py-1',
  large = 'h-12 text-lg px-2 py-1',
}

export enum ModalPosition {
  base = 'b-modal',
  bottom = 'bottom-modal',
  right = 'right-modal',
  left = 'left-modal',
  top = 'top-modal',
}

// is in production
export const isProduction = import.meta.env.PROD
const isSandbox = location.hostname.split('.').includes('app-sandbox')

const shouldBeDisplayed = (acc, item) => {
  item.subPages = item.subPages.reduce((acc, item) => {
    if ((!isProduction || !item.isAlpha || (item.isAlpha && isSandbox)) && item.isDisplayed)
      acc.push(item)
    return acc
  }, [])

  if ((!isProduction || !item.isAlpha || (item.isAlpha && isSandbox)) && item.isDisplayed)
    acc.push(item)
  return acc
}

export const getDrawerMenuSuperAdmin = () => [
  {
    moduleId: 'SUPER_ADMIN',
    display: 'common.drawerSuperAdmin.users.title',
    isHeader: true,
    prependIcon: PhUsersThree,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'SUPER_ADMIN',
    isDisplayed: guardUsers() === true,
    subPages: [
      {
        moduleId: 'SUPER_ADMIN',
        isDisplayed: guardUsers() === true,
        display: 'common.drawerSuperAdmin.users.listOfUsers',
        route: 'SuperAdminUsers',
        isHeader: false,
        prependIcon: PhUsersThree,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 'SUPER_ADMIN',
        isDisplayed: guardProfiles() === true,
        display: 'common.drawerSuperAdmin.users.profileList',
        route: 'SuperAdminProfiles',
        isHeader: false,
        prependIcon: PhUsersThree,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 'SUPER_ADMIN',
    display: 'common.drawerSuperAdmin.firm.title',
    isHeader: true,
    prependIcon: SolarCaseOutline,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'SUPER_ADMIN',
    isDisplayed: guardUsers() === true,
    subPages: [
      {
        moduleId: 'SUPER_ADMIN',
        isDisplayed: guardUsers() === true,
        display: 'common.drawerSuperAdmin.firm.personalisation',
        route: 'SuperAdminPersonalisation',
        isHeader: false,
        prependIcon: SolarPaintRollerLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 'SUPER_ADMIN',
        isDisplayed: guardConsumption() === true,
        display: 'common.drawerSuperAdmin.firm.consumptions',
        route: 'Consumption',
        isHeader: false,
        prependIcon: PhChartDonutDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
].reduce(shouldBeDisplayed, [])

export const getDrawerMenu = () => [
  {
    moduleId: 1,
    display: 'common.drawer.hrBase',
    isHeader: true,
    prependIcon: PhUsersThree,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'HR',
    isDisplayed: guardHrAccess() === true,
    subPages: [
      {
        moduleId: 1,
        isDisplayed: true,
        display: 'common.drawer.directory',
        route: 'HrBase',
        isHeader: false,
        prependIcon: PhUsersThree,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 1,
        isDisplayed: guardHrAlumniAccess() === true,
        display: 'common.drawer.alumniDirectory',
        route: 'HrAlumni',
        isAlpha: false,
        isHeader: false,
        prependIcon: PhUserSoundDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 1,
        isDisplayed: true,
        display: 'common.drawer.onboarding',
        isAlpha: true,
        route: 'Onboarding',
        isHeader: false,
        prependIcon: PhUserSwitch,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 1,
        isDisplayed: guardHrCreateResource() === true,
        display: 'common.drawer.hrCreate',
        isAlpha: false,
        route: 'HrCreate',
        isHeader: false,
        prependIcon: PhUserPlus,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 1,
        isDisplayed: guardHrStatsAccess() === true,
        display: 'common.drawer.hrStats',
        route: 'HrStats',
        isHeader: false,
        prependIcon: PhChartLineUpLight,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 1,
        isDisplayed: guardHrStatsAccess() === true,
        isAlpha: true,
        display: 'common.drawer.settings',
        route: 'HrSettings',
        isHeader: false,
        prependIcon: PhGearSixThin,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 4,
    display: 'common.drawer.recruitment',
    isHeader: true,
    prependIcon: IconDocumentSearch,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    isBeta: true,
    moduleName: 'RECRUITMENT',
    isDisplayed: guardRecruitmentAccess() === true,
    subPages: [
      {
        moduleId: 4,
        isDisplayed: true,
        display: 'common.drawer.myMeetings',
        route: 'Meetings',
        isHeader: false,
        isBeta: false,
        prependIcon: MdiTimelineClockOutline,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: guardCreateCandidate() === true, // FIXME get the right guard
        display: 'common.drawer.createCandidate',
        route: 'CreateCandidate',
        isHeader: false,
        isBeta: false,
        prependIcon: SolarUserPlusLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: true, // FIXME get the right guard
        display: 'common.drawer.recruitmentCalendar',
        route: 'RecruitmentCalendar',
        isHeader: false,
        isBeta: true,
        prependIcon: SolarCalendarLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: guardScreening() === true,
        display: 'common.drawer.screening',
        route: 'Screening',
        isHeader: false,
        isBeta: false,
        prependIcon: SolarDocumentsLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: guardPipe() === true,
        display: 'common.drawer.recruitmentProcess',
        route: 'RecruitmentProcess',
        isHeader: false,
        isBeta: true,
        isAlpha: false,
        prependIcon: PhKanbanLight,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        display: 'common.drawer.recruitmentDatabase',
        isDisplayed: guardCandidateBase() === true,
        route: 'RecruitmentDatabase',
        isHeader: false,
        isBeta: true,
        isAlpha: false,
        prependIcon: SolarDatabaseLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        display: 'common.drawer.jobBoard',
        isDisplayed: guardJobBoard() === true,
        route: 'JobBoardAdmin',
        isHeader: false,
        isAlpha: false,
        isBeta: false,
        prependIcon: SolarSuitcaseBroken,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: guardRecruitmentStatsAccess() === true, // statsAccess
        display: 'common.drawer.hrStats',
        route: 'RecruitmentStats',
        isHeader: false,
        prependIcon: PhChartLineUpLight,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: guardSettingsAccess() === true,
        display: 'common.drawer.settings',
        route: 'RecruitmentSettings',
        isHeader: false,
        prependIcon: PhGearSixThin,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 5,
    isDisplayed: guardProjectAccess() === true,
    display: 'common.drawer.project',
    isHeader: true,
    prependIcon: IconChart,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'PROJECT',
    subPages: [
      {
        moduleId: 5,
        isDisplayed: guardProjectCreation() === true,
        display: 'common.drawer.createProject',
        route: 'ProjectCreation',
        isHeader: false,
        prependIcon: IcOutlinePlaylistAdd,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 5,
        isDisplayed: true,
        display: 'common.drawer.projectList',
        route: 'Projects',
        isHeader: false,
        prependIcon: MdiFormatListText,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 5,
        isDisplayed: true,
        display: 'common.drawer.projectAdvancement',
        route: 'ProjectsAdvancement',
        isHeader: false,
        prependIcon: MdiFormatListText,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 5,
        isDisplayed: guardPlanningResource() === true,
        display: 'common.drawer.projectResourcePlanning',
        route: 'ProjectPlanningResources',
        isHeader: false,
        prependIcon: IcBaselineList,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 5,
        isDisplayed: guardPlanningResource() === true,
        display: 'common.drawer.projectPlanning',
        route: 'ProjectPlanningProjects',
        isHeader: false,
        prependIcon: IcBaselineList,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 20,
    isDisplayed: guardTimesheetAccess() === true,
    display: 'common.drawer.timesheet',
    isHeader: true,
    prependIcon: MaterialSymbolsNestClockFarsightAnalogOutlineRounded,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'TIMESHEET',
    subPages: [
      {
        moduleId: 20,
        isDisplayed: guardTimesheetAccess() === true,
        display: 'common.drawer.timesheets',
        route: 'Timesheets',
        isHeader: false,
        prependIcon: MaterialSymbolsNestClockFarsightAnalogOutlineRounded,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 6,
    isDisplayed: guardLeaveAccess() === true,
    display: 'common.drawer.leave',
    isHeader: true,
    prependIcon: PhAirplaneTakeoffLight,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'LEAVE',
    subPages: [
      {
        moduleId: 6,
        isDisplayed: guardLeaveAccess() === true,
        display: 'common.drawer.leaveAccount',
        route: 'LeaveAccount',
        isHeader: false,
        prependIcon: SolarCalculatorMinimalisticBroken,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardRequest() === true,
        display: 'common.drawer.leaveRequest',
        route: 'LeaveRequests',
        isHeader: false,
        prependIcon: MaterialSymbolsAssignmentOutlineRounded,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardNewRequest() === true,
        display: 'common.drawer.leaveNewRequest',
        route: 'NewLeaveRequest',
        isHeader: false,
        prependIcon: MaterialSymbolsAssignmentAddOutlineSharp,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardNewBulkRequest() === true,
        display: 'common.drawer.leaveNewRequestBulk',
        route: 'NewBulkLeaveRequest',
        isHeader: false,
        prependIcon: MaterialSymbolsAssignmentAddOutlineSharp,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardCalendar() === true,
        display: 'common.drawer.LeaveCalendar',
        route: 'LeaveCalendar',
        isHeader: false,
        prependIcon: MaterialSymbolsEventNoteOutlineRounded,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 7,
    isDisplayed: guardEvaluationAccess() === true,
    display: 'common.drawer.evaluation',
    isHeader: true,
    prependIcon: SolarDocumentTextLineDuotone,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'EVALUATION',
    subPages: [
      {
        moduleId: 7,
        isDisplayed: guardEvaluationAccess() === true,
        display: 'common.drawer.evaluationList',
        route: 'Evaluations',
        isHeader: false,
        prependIcon: SolarDocumentsLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 7,
        isDisplayed: guardCreationEvaluation() === true,
        display: 'common.drawer.evaluationCreate',
        route: 'EvaluationCreation',
        isHeader: false,
        prependIcon: SolarDocumentMedicineBroken,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },

  {
    moduleId: 9,
    isDisplayed: true,
    display: 'common.drawer.training',
    isHeader: true,
    prependIcon: SolarSquareAcademicCap2LineDuotone,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'TRAINING',
    isAlpha: false,
    isBeta: true,
    subPages: [
      {
        moduleId: 9,
        display: 'common.drawer.trainingNew',
        route: 'TrainingNew',
        isHeader: false,
        isDisplayed: true,
        prependIcon: IconNewDashboard,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
        isAlpha: true,
      },
      {
        moduleId: 9,
        display: 'common.drawer.trainingList',
        route: 'TrainingList',
        isHeader: false,
        isDisplayed: true,
        prependIcon: IconSchool,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
        isAlpha: false,
        isBeta: true,
      },
      {
        moduleId: 9,
        display: 'common.drawer.trainingCalendar',
        route: 'TrainingPlanning',
        isHeader: false,
        isDisplayed: true,
        prependIcon: IconSchool,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
        isAlpha: true,
      },
      {
        moduleId: 9,
        display: 'common.drawer.myTraining',
        route: 'MyTraining',
        isHeader: false,
        isDisplayed: true,
        prependIcon: IconSchool,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
        isAlpha: false,
        isBeta: true,
      },
    ],
  },
  {
    moduleId: 13,
    display: 'Expense',
    isHeader: true,
    prependIcon: SolarBillListLineDuotone,
    appendBadge: false,
    isDisplayed: guardExpenseAccess() === true,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'EXPENSE',
    isAlpha: false,
    subPages: [
      // {
      //   moduleId: 13,
      //   isDisplayed: true,
      //   display: 'New expense',
      //   route: 'NewExpense',
      //   isHeader: false,
      //   prependIcon: SolarDocumentAddLineDuotone,
      //   appendBadge: false,
      //   color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      // },
      {
        moduleId: 13,
        isDisplayed: guardExpenseCreation() === true,
        display: 'Reports',
        route: 'Reports',
        isHeader: false,
        prependIcon: SolarDocumentAddLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 13,
        isDisplayed: guardExpenseSettings() === true,
        display: 'Settings',
        route: 'ExpenseSettings',
        isHeader: false,
        prependIcon: PhGearSixThin,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 23,
    display: 'Dashboard',
    isHeader: true,
    prependIcon: SolarChartOutline,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'DASHBOARD',
    isDisplayed: guardDashboardAccess() === true,
    subPages: [
      {
        moduleId: 23,
        isDisplayed: guardProduction() === true,
        display: 'Production',
        route: 'DashboardProduction',
        isHeader: false,
        prependIcon: SolarChartLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 23,
        isDisplayed: guardSales() === true,
        display: 'Sales',
        route: 'DashboardSales',
        isHeader: false,
        prependIcon: SolarMoneyBagLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 23,
        isDisplayed: guardCashFlow() === true,
        display: 'Cashflow',
        route: 'DashboardCashflow',
        isHeader: false,
        prependIcon: SolarWalletMoneyOutline,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 23,
        isDisplayed: guardResource() === true,
        display: 'Resource',
        route: 'DashboardResource',
        isHeader: false,
        prependIcon: PhUserList,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },

].reduce(shouldBeDisplayed, [])
