import type { RouteRecordRaw } from 'vue-router'

const MODULE_NAME = 'Academy'
const MODULE_ID = 14

const trainingRoutes: Array<RouteRecordRaw> = [
  {
    path: '/academy',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'ElearningList',
        component: () => import('@/views/academy/List.vue'),
        meta: {
          requiresAuth: true,
          adminAccess: true,
        },
      },
      {
        path: ':lessonId',
        children: [
          {
            path: '',
            name: 'LessonDetails',
            component: () => import('@/views/academy/LessonDetails.vue'),
            meta: {
              requiresAuth: true,
              adminAccess: true,
            },
          }
        ]
      }
    ]
  },





]

export default trainingRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
