import type { AxiosInstance } from 'axios'
import type { ICountry, ICurrentJob, IFactoryFunctionRequest, IFactoryResponse, IPaginated, IResource } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'
import { useUserStore } from '@/config/store/userStore'
import type { ICandidateApplication } from '@/services/resource/interfaces'
import degreesService from '@/services/resource/degreeHttp.service'
import skillsService from '@/services/resource/skillHttp.service'

let userStore
const getConnectedUser = () => {
  if (!userStore)
    userStore = useUserStore()
  return userStore
}

const resource = 'resources'

// const getConnectedResource = (axios: AxiosInstance) => (): AxiosPromise => {
//     return axios.get(`${BASE_URL}/${API_MAIN_VERSION}/${resource}/me`, {headers: {authorization: `Bearer ${userStore.getToken}`}});
// }

const createResource = (axios: IFactoryFunctionRequest<IResource, IResource>) => (resourceToSend: any) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    data: resourceToSend,
  })
}


// const settings = ref({
//   recruitmentDate: undefined,
//   recruitmentDateEnd: undefined,
//   departureDate: undefined,
//   departureDateEnd: undefined,
//   retreiveSearchDisplay: true,
//   retreiveSearchEvaluated: true,
//   retreiveSearchEvaluator: true,
//   jobs: [],
//   teams: [],
//   offices: [],
//   groups: [],
// })

interface IResourceListQuery {
  page: number
  limit: number
  display?: string
  settings?: {
    recruitmentDate?: Date
    recruitmentDateEnd?: Date
    departureDate?: Date
    departureDateEnd?: Date
    jobs?: string[]
    teams?: string[]
    offices?: string[]
    groups?: string[]
  }
}

interface IHrFilterQuery {
  display : string
  limit : number
  page : number
  resourceType : number
  jobs : [string]
  teams : [string]
  offices : [string]
  userGroups : [string]
  recruitmentDate : Date
  recruitmentDateEnd : Date
  departureDate : Date
  departureDateEnd : Date
  resources : [string]
}
const hrBaseList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any>) => (query : IHrFilterQuery) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/employeeList/paginated`,
    query: { ...query },
  })
}

const alumniBaseList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any>) => (query: { page: number; limit: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/alumniList/paginated`,
    query,
  })
}


const getHrResourceProfile = (axios: IFactoryFunctionRequest<IResource, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/hrProfile/${resourceId}`,
  })
}

const getAllLight = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({ firmId }: { firmId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firm/light/${firmId}`,
  })
}

const showPaginatedLight = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({
  page = 1,
  search = '',
  jobs = [],
  limit = 30,
  skills = '[]',
}: { page?: number; search?: string; jobs?: string[]; limit?: number, skills: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/paginated/light`,
    query: { page, search, jobs, limit, skills },
  })
}

const showPaginatedLeaveValidator = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({
  page = 1,
  search = '',
  jobs = [],
  limit = 30,
  leaveTypeId,
  startDate,
  endDate,
}: { page?: number; search?: string; jobs?: string[]; limit?: number; leaveTypeId: string; startDate: Date; endDate: Date }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/leave/validators/${leaveTypeId}/${startDate}/${endDate}`,
    query: { page, search, jobs, limit },
  })
}

const updateResource = (axios: IFactoryFunctionRequest<IResource, IResource>) => (resourceId: string, resourceToSend: any) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${resourceId}`,
    data: resourceToSend,
  })
}

const createCandidateApplication = (axios: IFactoryFunctionRequest<undefined, ICandidateApplication>) => (candidateApplication: ICandidateApplication) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidateApplication`,
    data: candidateApplication,
  })
}

const deleteCandidate = (axios: IFactoryFunctionRequest<IResource, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidate/${resourceId}`,
  })
}

const getConnectedResource = (axios: IFactoryFunctionRequest<IResource, any>) => (): IFactoryResponse<IResource, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/connectedUser`,
  })
}

const getOne = (axios: IFactoryFunctionRequest<IResource, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/profile/${resourceId}`,
  })
}
const getOneToStaff = (axios: IFactoryFunctionRequest<IResource, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/getOne/toStaff/${resourceId}`,
  })
}
const getInternalInterval = (axios: IFactoryFunctionRequest<Array<IResource>, any>) => ({
  search,
  startDate,
  endDate,
  jobs = [],
  skills = []
}: { search?: string; startDate: Date; endDate: Date; jobs?: string[], skills?: any[] }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/planning/interval/${startDate}/${endDate}`,
    query: { jobs, search, skills: JSON.stringify(skills) },
  })
}

const getSuperAdminResourceList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any>) => (query: { page: number; limit: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/superAdminList`,
    query,
  })
}

const filter = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any>) => (query: { page: number; limit: number; display?: string, project: [string] }) => {
  if (!query.project)
        delete query.project
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query,
  })
}

const getResourceCountByProfileId = (axios: IFactoryFunctionRequest<{ count: number }, any>) => (profileId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/profile/${profileId}/count`,
  })
}

const getSmallestResourceInfo = (axios: IFactoryFunctionRequest<{ firstname: string; lastname: string; picture: string }[], any>) => (resourceIds: string[]) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/smallestResources`,
    data: { resourceIds },
  })
}

const getResourceBulkLeaveCreate = (axios: IFactoryFunctionRequest<IPaginated<{ _id: string; firstname: string; lastname: string; picture: string }>, any>) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/leave/bulkCreate`,
    query,
  })
}
const updateResourcePicture = (axios: IFactoryFunctionRequest<{ picture: string }, any>) => (resourceId: string, { base64 }: { base64: string }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/picture/${resourceId}`,
    data: { data: base64 },
  })
}

const retreiveInterviewAttendee = (axios: IFactoryFunctionRequest<{ _id: string; firstname: string; lastname: string; userId: string; user: { _id: string; email: string } }, any>) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/recruitment/retreiveInterviewAttendee/${resourceId}`,
  })
}

const exportResourcesToExel = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export`,
    query,
    immediate: false,
  })
}
const exportResourcesRetentionToExcel = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export/retention`,
    query,
    immediate: false,
  })
}
const exportResourcesPresenceToExcel = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export/presence`,
    query,
    immediate: false,
  })
}

const candidateToResource = (axios: IFactoryFunctionRequest<IResource, any >) => (body) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidateToResource`,
    data: body,
  })
}
const partialUpdate = (axios: IFactoryFunctionRequest<IResource, any >) => (body) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${body._id}`,
    data: body,
  })
}

const searchIfResourceExist = (axios: IFactoryFunctionRequest<IResource[], any >) => ({ firstname, lastname, email }: { firstname: string; lastname: string; email: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/searchIfResourceExist`,
    query: {
      firstname,
      lastname,
      email,
    },
  })
}

const getManagedResources = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/managedResources/${resourceId}`,
  })
}

// router.patch('/:resourceId/nationalities', auth.isAuth(), controller.updateNationalities);
const updateNationalities = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, { nationalities, nationality }: { nationalities: any[]; nationality: string }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/nationalities`,
    data: { nationalities, nationality },
  })
}

const getResourceOfficesCountries = (axios: IFactoryFunctionRequest<Array<ICountry>, any >) => (resourceId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/OfficesCountries/${resourceId}`,
  })
}

/** 
 * use v1 cause use cron futur job in other country or other office
 * @param resourceId 
 * @param alumniPeriod
 * @param isDepartureReplace
 * @returns IRessource 
*/
const pushJob = (axios: IFactoryFunctionRequest<any, any>) => ({ resourceId, job }: { resourceId: string, job: ICurrentJob }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${resourceId}/addJob`,
    data: {
      job: job,
    },
  })
}


/** 
 * 
 * @param resourceId 
 * @param alumniPeriod
 * @param isDepartureReplace
 * @returns ICurrentJob data.alumniPeriod 
*/
const pushAlumniPeriod = (axios: IFactoryFunctionRequest<any, any>) => ({ resourceId, alumniPeriod, isDepartureReplace = false }: { resourceId: string, alumniPeriod: ICurrentJob, isDepartureReplace?: boolean }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/alumniPeriod`,
    data: {
      alumniPeriod,
      isDepartureReplace,
    },
  })
}

const updateJob = (axios: IFactoryFunctionRequest<any, any>) => (resourceId: string, jobToUpdate : ICurrentJob) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/job/${jobToUpdate._id}`,
    data: {jobToUpdate},
  })
}


/**
 * Delete Resource job AND alumni period
 * @param axios 
 * @returns 
 */
const deleteJob = (axios: IFactoryFunctionRequest<any, any>) => ({ id, resourceId }: { id: string, resourceId: string }) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/job/${id}`,
  })
}

const sendInvitations = (axios: IFactoryFunctionRequest<any, any>) => ({ resourceIds, resetPassword }: { resourceIds: string[]; resetPassword: boolean }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/sendInvitations`,
    data: { resourceIds, resetPassword },
  })
}

const deleteResources = (axios: IFactoryFunctionRequest<any, any>) => (resourceIds: string[]) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/deleteResources`,
    data: { resourceIds },
  })
};

/**
 * Export HR Base to Excel file (use post cause of the size of parameters)
 * @param axios 
 * @returns 
 */
const exportHrBase = (axios: IFactoryFunctionRequest<any, any>) => ({filters, fields} = {filters: {}, fields: []}) => {
  // filters = filters || {}
  // fields = fields || []
  const data = { filters, fields }
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export/hrBase`,
    data,
  })
}

const getEvaluationResourceSkill = (axios: IFactoryFunctionRequest<any, any>) => ({ resourceId }: { resourceId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/evaluationResourceSkill/${resourceId}`,
  })
}

const cancelHiring = (axios: IFactoryFunctionRequest<any, any>) => (candidateId: string, processId: string) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/cancelHired/${candidateId}/process/${processId}`,
  })
}


/**
 * Get all resources recruitment date and departure date for calendar between two dates 
 * @param {startDate: Date, endDate: Date} query
 * @returns {IResource[]}
 */
const calendarResource = (axios: IFactoryFunctionRequest<any, any>) => (query): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/calendarResource`,
    query
  })
}

export default (axios: AxiosInstance) => ({
  // getConnectedResource: getConnectedResource(axios),
  createResource: createResource(useAxios(axios)),
  getAllLight: getAllLight(useAxios(axios)),
  showPaginatedLight: showPaginatedLight(useAxios(axios)),
  getHrResourceProfile: getHrResourceProfile(useAxios(axios)),
  hrBaseList: hrBaseList(useAxios(axios)),
  alumniBaseList: alumniBaseList(useAxios(axios)),
  updateResource: updateResource(useAxios(axios)),
  partialUpdate: partialUpdate(useAxios(axios)),
  createCandidateApplication: createCandidateApplication(useAxios(axios)),
  getConnectedResource: getConnectedResource(useAxios(axios)),
  getOne: getOne(useAxios(axios)),
  getOneToStaff: getOneToStaff(useAxios(axios)),
  getInternalInterval: getInternalInterval(useAxios(axios)),
  getResourceCountByProfileId: getResourceCountByProfileId(useAxios(axios)),
  showPaginatedLeaveValidator: showPaginatedLeaveValidator(useAxios(axios)),
  getSuperAdminResourceList: getSuperAdminResourceList(useAxios(axios)),
  getSmallestResourceInfo: getSmallestResourceInfo(useAxios(axios)),
  filter: filter(useAxios(axios)),
  getResourceBulkLeaveCreate: getResourceBulkLeaveCreate(useAxios(axios)),
  updateResourcePicture: updateResourcePicture(useAxios(axios)),
  retreiveInterviewAttendee: retreiveInterviewAttendee(useAxios(axios)),
  exportResourcesToExel: exportResourcesToExel(useAxios(axios)),
  candidateToResource: candidateToResource(useAxios(axios)),
  deleteCandidate: deleteCandidate(useAxios(axios)),
  searchIfResourceExist: searchIfResourceExist(useAxios(axios)),
  exportResourcesRetentionToExcel: exportResourcesRetentionToExcel(useAxios(axios)),
  getManagedResources: getManagedResources(useAxios(axios)),
  updateNationalities: updateNationalities(useAxios(axios)),
  getResourceOfficesCountries: getResourceOfficesCountries(useAxios(axios)),
  sendInvitations: sendInvitations(useAxios(axios)),
  pushAlumniPeriod: pushAlumniPeriod(useAxios(axios)),
  updateJob: updateJob(useAxios(axios)),
  pushJob: pushJob(useAxios(axios)),
  deleteJob: deleteJob(useAxios(axios)),
  exportHrBase: exportHrBase(useAxios(axios)),
  getEvaluationResourceSkill: getEvaluationResourceSkill(useAxios(axios)),
  exportResourcesPresenceToExcel: exportResourcesPresenceToExcel(useAxios(axios)),
  cancelHiring: cancelHiring(useAxios(axios)),
  calendarResource: calendarResource(useAxios(axios)),
  deleteResources: deleteResources(useAxios(axios)),
  degrees: degreesService(axios),
  skills: skillsService(axios),
})
