<script setup lang="ts">
import { bgColors } from '@/services/application/constantes';
import type { StrictTailwindRoundedClass } from '@/services/application';

const sizes = {
  dense: 'h-6 text-xs',
  small: 'h-8 text-sm',
  medium: 'h-10 text-base',
  large: 'h-12 text-lg',
};

type BLabelProps = {
  bgColor?: keyof typeof bgColors;
  size?: keyof typeof sizes;
  rounded?: StrictTailwindRoundedClass;
};

const props = withDefaults(defineProps<BLabelProps>(), {
  bgColor: 'base',
  size: 'small',
  rounded: 'rounded-lg',
});
</script>

<template>
  <span
    class="px-2 flex items-center justify-around gap-2"
    :class="[bgColors[props.bgColor], sizes[props.size], props.rounded]"
  >
    <slot />
  </span>
</template>
