import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'
import { RightsReadWriteUtils } from '@bc-library/interface'

const moduleId = 1

const canUserAccessModule = (user) => {
  const isFirmAccess = isModuleActive(user.firm, moduleId)
  return isFirmAccess && user.profile.modules.hr.isAccess
}
export const guardResourceProfile = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.hr.resourceProfileAccess ? user.profile.modules.hr.resourceProfileAccess : { name: 'Home' }
}

export const guardHrAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const accessModule = canUserAccessModule(user)
  return accessModule || { name: 'Home' }
}

export const guardHrAlumniAccess = () => {
    const userStore = useUserStore()
    const user = userStore.user
    return user.profile.modules?.hr?.alumniAccess ? user.profile.modules.hr.alumniAccess : { name: 'Home' }
}

export const guardHrStatsAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.hr.statsAccess ? user.profile.modules.hr.statsAccess : { name: 'Home' }
}

export const guardHrCreateResource = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.hr.createResourceAccess ? user.profile.modules.hr.createResourceAccess : { name: 'Home' }
}


export const guardHrCanPromote = () => {
  const userStore = useUserStore()
  const userProfile = userStore.user.profile
  const userProfileAccessJobPath = userProfile.modules.hr?.accessJobPath
  return RightsReadWriteUtils.canWrite(userProfileAccessJobPath) 
}

export const guardSettingsAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.hr.settingsAccess ? user.profile.modules.hr.settingsAccess : { name: 'Home' }
}
