import type { RouteRecordRaw } from 'vue-router'
import authRoutes from '@/config/router/routes/authRoutes'
import trainingRoutes from '@/config/router/routes/trainingRoutes'
import hrRoutes from '@/config/router/routes/hrRoutes'
import projectRoutes from '@/config/router/routes/projectRoutes'
import recruitmentRoutes from '@/config/router/routes/recruitmentRoutes'
import applicationRoutes from '@/config/router/routes/applicationRoutes'
import dashboardRoutes from '@/config/router/routes/dashboardRoutes'
import documentationRoutes from '@/config/router/routes/documentationRoutes'
import superAdminRoutes from '@/config/router/routes/superAdminRoutes'
import timesheetRoutes from '@/config/router/routes/timesheetRoutes'
import leaveRoutes from '@/config/router/routes/leaveRoutes'
import salesRoutes from '@/config/router/routes/saleRoutes'
import managementRoutes from '@/config/router/routes/managementRoutes'
import evaluationRoutes from '@/config/router/routes/evaluationRoutes'
import invoiceRoutes from '@/config/router/routes/invoiceRoutes'
import expenseRoutes from '@/config/router/routes/expenseRoutes'
import AcademyRoutes from '@/config/router/routes/AcademyRoutes'
import { useUserStore } from '@/config/store/userStore'

const defaultMeta = {
  requiresAuth: true,
  navbarNotRequired: false,
  moduleId: 0,
}

export const guardExport = () => {
  const userStore = useUserStore()
  const user = userStore.user
  return user.profile.modules.export?.isAccess ? user.profile.modules.export.isAccess : { name: 'Home' }
}

export const isCandidate = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const isCandidateProfile = user.profile.isCandidateProfile
  return isCandidateProfile || { name: 'Home' }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/candidate',
    name: 'CandidateOnboarding',
    component: () => import('@/views/recruitment/CandidateOnboarding.vue'),
    meta: {
      requiresAuth: true,
      navbarNotRequired: true,
      titleTranslationKey: 'pageTitle.candidateOnboarding',
    },
    beforeEnter: [isCandidate],
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      ...defaultMeta,
    },
  },
  {
    path: '/playground',
    name: 'Playground',
    component: () => import('@/views/Playground.vue'),
    meta: {
      ...defaultMeta,
    },
  },
  {
    path: '/export',
    name: 'Export',
    component: () => import('@/views/export/Export.vue'),

    meta: {
      titleTranslationKey: 'pageTitle.dataExport',
    },
    beforeEnter: [guardExport],
  },

]

export default [
  ...routes,
  ...recruitmentRoutes,
  ...trainingRoutes,
  ...authRoutes,
  ...projectRoutes,
  ...hrRoutes,
  ...applicationRoutes,
  ...dashboardRoutes,
  ...documentationRoutes,
  ...superAdminRoutes,
  ...timesheetRoutes,
  ...leaveRoutes,
  ...salesRoutes,
  ...managementRoutes,
  ...evaluationRoutes,
  ...invoiceRoutes,
  ...expenseRoutes,
  ...AcademyRoutes,
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/application/NotFound.vue') },
]
