import type { RouteRecordRaw } from 'vue-router'
import { guardExpenseAccess, guardExpenseCreation, guardExpenseSettings } from '@/config/router/navigationsGuards/guardExpense'

const MODULE_NAME = 'EXPENSE'
const MODULE_ID = 13

const projectRoutes: Array<RouteRecordRaw> = [
  {
    path: '/expense',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardExpenseAccess],
    children: [
      {
        path: 'settings',
        name: 'ExpenseSettings',
        component: () => import('@/views/expense/Settings.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projects',
        },
        beforeEnter: [guardExpenseSettings],
      },
      {
        path: '',
        name: 'NewExpense',
        component: () => import('@/views/expense/Home.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projects',
        },
        beforeEnter: [],
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('@/views/expense/Report/Reports.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projects',
        },
        beforeEnter: [guardExpenseCreation],
      },
      {
        path: 'reports/:reportId',
        name: 'Report',
        component: () => import('@/views/expense/Report/Report.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projects',
        },
        beforeEnter: [guardExpenseCreation],
      },
    ],
  },
]

export default projectRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
