<script lang="ts" setup>
import { useDark } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { computed, onMounted, provide, ref, shallowRef, watch } from 'vue'
import ChangePasswordModal from './components/resource/ChangePasswordModal.vue'
import NavigationDrawer from '@/components/navigationDrawer/navigationDrawer.vue'
import BAlert from '@/components/common/BAlert.vue'
import { useUserStore } from '@/config/store/userStore'
import { useApplicationStore } from '@/config/store/applicationStore'
import { resourceApi } from '@/services/api'
import { setFirmThemeColor } from '@/services/application'

const userStore = useUserStore()
useDark()
const route = useRoute()
const isOpenChangePasswordModal = ref(false)

const changeModalComputed = computed({
  get: () => {
    if (!userStore.user)
      return false
    return isOpenChangePasswordModal.value || userStore.user?.user?.toChangePassword
  },
  set: (value) => {
    isOpenChangePasswordModal.value = value
  },
})

const isNavbarHidden = computed(() => (!userStore.user || userStore.user.profile.isCandidateProfile))

const scrollBlocked = ref(false)

provide('scrollBlocked', scrollBlocked)

const applicationStore = useApplicationStore()
if (userStore.user) {
  resourceApi.getConnectedResource().promise.then((resource) => {
    userStore.user = resource
    setFirmThemeColor(userStore.user.firm)
  }).catch(err => console.log('refresh user error', err))

  setFirmThemeColor(userStore.user.firm)
}

const removeError = (error) => {
  applicationStore.removeError(error)
}

if (!userStore.user) {
  const {
    needRefresh,
    updateServiceWorker,
  } = useRegisterSW()

  watch(needRefresh, () => {
    if (needRefresh.value)
      updateServiceWorker()
  })
}
</script>

<template>
  <div
    id="bc-app" class="relative h-screen transition-all flex flex-col w-screen overflow-hidden"
    :class="[!isNavbarHidden ? !applicationStore.drawer.fixed ? 'md:pl-14' : 'md:pl-64' : undefined, scrollBlocked ? 'overflow-hidden' : 'overflow-y-auto']"
  >
    <ChangePasswordModal v-model:is-open="changeModalComputed" />
    <NavigationDrawer v-if="!isNavbarHidden" class="print:hidden" @change-password="changeModalComputed = true" />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <Suspense>
          <component :is="Component" :key="route.path" />
        </Suspense>
      </transition>
    </router-view>

    <Teleport to="body">
      <div class="fixed bottom-6 right-6 z-100">
        <transition-group name="slide-x">
          <BAlert v-for="error in applicationStore.errors" :key="error.id" :alert="error" shadow size="normal" @close="removeError" />
        </transition-group>
      </div>
    </Teleport>
  </div>
</template>

<style>
</style>
