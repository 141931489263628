import type { RouteRecordRaw } from 'vue-router'

const MODULE_NAME = 'TRAINING'
const MODULE_ID = 9

const trainingRoutes: Array<RouteRecordRaw> = [
  {
    path: '/training',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'TrainingList',
        component: () => import('@/views/training/TrainingList.vue'),
        meta: {
          requiresAuth: true,
          adminAccess: true,
        },
      },
      {
        path: 'new',
        name: 'TrainingNew',
        component: () => import('@/views/training/trainingNew/TrainingNew.vue'),
        meta: {
          requiresAuth: true,
          adminAccess: true,
        },
      },
      {
        path: 'my-trainings',
        name: 'MyTraining',
        component: () => import('@/views/training/MyTraining.vue'),
        meta: {
          requiresAuth: true,
          adminAccess: true,
        },
      },
      {
        path: ':trainingId',
        children: [
          {
            path: '',
            name: 'TrainingDetails',
            component: () => import('@/views/training/TrainingDetails.vue'),
            meta: {
              requiresAuth: true,
              adminAccess: true,
            },
          },
          {
            path: 'new',
            name: 'TrainingSessionNew',
            component: () => import('@/views/training/NewTrainingSession.vue'),
            meta: {
              requiresAuth: true,
              adminAccess: true,
            },
          },
        ]
      },
      {
        path: 'session/:trainingSessionId',
        name: 'TrainingSessionDetails',
        component: () => import('@/views/training/TrainingSessionDetails.vue'),
        meta: {
          requiresAuth: true,
          adminAccess: true,
        },
      },
      {
        path: 'planning',
        name: 'TrainingPlanning',
        component: () => import('@/views/training/trainingPlanning/TrainingPlanning.vue'),
        meta: {
          requiresAuth: true,
          adminAccess: true,
        },
      },
    ]
  },





]

export default trainingRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
