import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import type { RouteLocation } from 'vue-router'
import type { TColors } from '@/services/application/interfaces'
import { ref } from 'vue'

type tailwindColors = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 950

type IError = {
  id?: string
  title?: string
  message?: string
  color: TColors
  duration?: number
  version?: number
}
type IState = {
  errors: Array<IError>
  previousApplicationRoute: RouteLocation | null
  drawer: {
    fixed: boolean
  }
  theme?: {
    colors: {
      hsl: {
        [tailwindColors: number | string]: string
      }
      hex: {
        [tailwindColors: number | string]: string
      }
    }
  }
}

export const useApplicationStore = defineStore('application', () => {

  const errors = ref([])

  const drawer = ref({
    fixed: true,
  })
  const theme = ref<TColors>()

  const previousApplicationRoute = ref<RouteLocation | null>(null)

  const removeError = (index: number) => {
    errors.value.splice(index, 1)
  }

  const pushError = ({ color = 'error', duration = 5000, version = 0, ...error }: IError) => {
    const id = `${new Date().getTime()}}`
    const foundExistingError = errors.value.findIndex(currentError => {
      return currentError.title === error.title && error.message === currentError.message
    })

    if(foundExistingError !== -1)
      return errors.value[foundExistingError].version = errors.value[foundExistingError].version + 1



    errors.value.push({ ...error, id, color, duration, version })
  }

  const resetErrors = () => {
    errors.value = []
  }

  return {
    errors,
    drawer,
    theme,
    previousApplicationRoute,
    pushError,
    removeError,
    resetErrors,
  }


},
  {
    persist: true,
  }
)
