import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IPaginated, IResource } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'recruitmentProcess'

const screeningList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any >) => (query: { page?: number; limit?: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/inbox/paginated`,
    query,
  })
}

const getProcessEvaluation = (axios: IFactoryFunctionRequest<IResource, any >) => ({ processId }: { processId: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/evaluation/${processId}`,
  })
}

const updateRecruitmentProcess = (axios: IFactoryFunctionRequest<IResource, any >) => (process: unknown) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${process._id}`,
    data: process,
  })
}

const partialUpdateRecruitmentProcess = (axios: IFactoryFunctionRequest<IResource, any >) => (processId: string, process: unknown) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${processId}`,
    data: process,
  })
}

const candidateBaseList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any >) => (query: { page?: number; limit?: number; search?: string }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/getDatabaseProcesses`,
    query,
  })
}
const getProcessList = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/kanban`,
    query,
  })
}
const getProcessInterviewPage = (axios: IFactoryFunctionRequest<IResource, any >) => (processId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/interviews/${processId}`,
  })
}

const addCommentToProcess = (axios: IFactoryFunctionRequest<any, any>) => (processId, comment: any) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/addComment/${processId}`,
    data: comment,
  })
}

const addNoteToProcess = (axios: IFactoryFunctionRequest<any, any>) => (processId, note: any) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/addNote/${processId}`,
    data: note,
  })
}

const exportCandidatesToExel = (axios: IFactoryFunctionRequest<IResource, any >) => (data, query) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export`,
    query,
    immediate: false,
  })
}

const getBase64CandidatePdf = (axios: IFactoryFunctionRequest<IResource, any >) => (processId, query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/candidatePdf/${processId}`,
    query,
  })
}

const createInterviewProcess = (axios: IFactoryFunctionRequest<any, any >) => (datas) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${datas.processId}/rounds/${datas.roundIndex}`,
    data: datas,
  })
}

const attachMeetingToInterview = (axios: IFactoryFunctionRequest<any, any >) => ({ processId, roundIndex, interview, defaultRound }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${processId}/rounds/${roundIndex}/attachMeeting`,
    data: {
      interview,
      defaultRound,
    },
  })
}

const updateInterviewMeeting = (axios: IFactoryFunctionRequest<any, any >) => ({ processId, interview }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/updateInterviewMeeting/${processId}/interview/${interview._id}`,
    data: { interview },
  })
}

const deleteInterview = (axios: IFactoryFunctionRequest<any, any >) => ({ processId, roundId, interviewId }) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${processId}/rounds/${roundId}/interviews/${interviewId}`,
  })
}

const fillCandidateEvaluation = (axios: IFactoryFunctionRequest<any, any >) => ({ closeEvaluation, processId, roundId, interviewId, recruiterId, recruiter, round }) => {
  return axios({
    method: EMethod.patch,
    query: { closeEvaluation },
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/fillEvaluation/${processId}/rounds/${roundId}/interviews/${interviewId}/recruiter/${recruiterId}`,
    data: { recruiter, round },
  })
}

const partialUpdateRound = (axios: IFactoryFunctionRequest<any, any >) => ({ processId, roundId, round }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${processId}/rounds/${roundId}`,
    data: round,
  })
}

const partialUpdateRoundRecruiter = (axios: IFactoryFunctionRequest<any, any >) => ({ processId, roundId, interviewId, recruitmentId, values }) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${processId}/rounds/${roundId}/interview/${interviewId}/recruitment/${recruitmentId}`,
    data: values,
  })
}

const extractCandidateCV = (axios: IFactoryFunctionRequest) => (data, firmId): IFactoryResponse<any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/extractPdfDatas/${firmId}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const candidateProcessOnboardingPage = (axios: IFactoryFunctionRequest<any, any>) => (): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidate/onboarding`,
  })
}

const uploadCandidateFile = (axios: IFactoryFunctionRequest) => ({ processId, type, data }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidate/${processId}/file/${type}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
const deleteCandidateFile = (axios: IFactoryFunctionRequest) => ({ processId, type }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidate/${processId}/file/${type}`,
  })
}

const retreiveMailHashTagsInfo = (axios: IFactoryFunctionRequest) => ({ processId }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/mailHashTags/${processId}`,
  })
}
const sendMailTemplateCandidate = (axios: IFactoryFunctionRequest) => (datas: { subject: string; html: string; processId: string; includeSignature: boolean; attachments: { fileName: string; content: string; encoding: 'base64' }[] }[]): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/sendMailTemplateToCandidate`,
    data: datas,
  })
}

const showMyRecruitmentProcesses = (axios: IFactoryFunctionRequest) => ({ query }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/myRecruitmentProcesses`,
    query,
  })
}

const showCandidateProcesses = (axios: IFactoryFunctionRequest) => ({ candidateId }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/candidate/${candidateId}/processes`,
  })
}

const createCandidateApplication = (axios: IFactoryFunctionRequest) => (data): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/createCandidateApplication`,
    data,
  })
}

const calendarMeeting = (axios: IFactoryFunctionRequest) => (query): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/calendarMeeting`,
    query
  })
}

export default (axios: AxiosInstance) => ({
  screeningList: screeningList(useAxios(axios)),
  updateRecruitmentProcess: updateRecruitmentProcess(useAxios(axios)),
  partialUpdateRecruitmentProcess: partialUpdateRecruitmentProcess(useAxios(axios)),
  candidateBaseList: candidateBaseList(useAxios(axios)),
  getProcessList: getProcessList(useAxios(axios)),
  exportCandidatesToExel: exportCandidatesToExel(useAxios(axios)),
  getProcessInterviewPage: getProcessInterviewPage(useAxios(axios)),
  getBase64CandidatePdf: getBase64CandidatePdf(useAxios(axios)),
  addCommentToProcess: addCommentToProcess(useAxios(axios)),
  addNoteToProcess: addNoteToProcess(useAxios(axios)),
  createInterviewProcess: createInterviewProcess(useAxios(axios)),
  updateInterviewMeeting: updateInterviewMeeting(useAxios(axios)),
  deleteInterview: deleteInterview(useAxios(axios)),
  getProcessEvaluation: getProcessEvaluation(useAxios(axios)),
  fillCandidateEvaluation: fillCandidateEvaluation(useAxios(axios)),
  partialUpdateRound: partialUpdateRound(useAxios(axios)),
  partialUpdateRoundRecruiter: partialUpdateRoundRecruiter(useAxios(axios)),
  extractCandidateCV: extractCandidateCV(useAxios(axios)),
  candidateProcessOnboardingPage: candidateProcessOnboardingPage(useAxios(axios)),
  uploadCandidateFile: uploadCandidateFile(useAxios(axios)),
  deleteCandidateFile: deleteCandidateFile(useAxios(axios)),
  retreiveMailHashTagsInfo: retreiveMailHashTagsInfo(useAxios(axios)),
  sendMailTemplateCandidate: sendMailTemplateCandidate(useAxios(axios)),
  showMyRecruitmentProcesses: showMyRecruitmentProcesses(useAxios(axios)),
  createCandidateApplication: createCandidateApplication(useAxios(axios)),
  attachMeetingToInterview: attachMeetingToInterview(useAxios(axios)),
  showCandidateProcesses: showCandidateProcesses(useAxios(axios)),
  calendarMeeting: calendarMeeting(useAxios(axios)),

})
