import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IPaginated, IResource } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'resources'

const pushDegree = (axios: IFactoryFunctionRequest<IResource["degrees"][0], IResource["degrees"][0]>) => (resourceId: string, degreeToPush: IResource["degrees"][0]) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/degrees`,
    data: {degree:degreeToPush},
  })
}

const updateDegree = (axios: IFactoryFunctionRequest<IResource["degrees"][0], IResource["degrees"][0]>) => (resourceId: string, degreeToUpdate: IResource["degrees"][0]) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/degrees`,
    data: {degree:degreeToUpdate},
  })
}

const deleteDegree = (axios: IFactoryFunctionRequest<any, any>) => (resourceId: string, degreeId: string) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/degrees/${degreeId}`,
  })
}


export default (axios: AxiosInstance) => ({
  updateDegree: updateDegree(useAxios(axios)),
  pushDegree: pushDegree(useAxios(axios)),
  deleteDegree: deleteDegree(useAxios(axios)),
})
