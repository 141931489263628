import { useUserStore } from '@/config/store/userStore'
import { isModuleActive } from '@/services/application'

const moduleId = 13

const canUserAccessModule = (user) => {
  // const isFirmAccess = isModuleActive(user.firm, moduleId)
  // return isFirmAccess && user.profile.modules.evaluation.isAccess
  return user.profile.modules?.expense?.isAccess
}

export const guardExpenseAccess = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = canUserAccessModule(user)
  return canAccess || { name: 'Home' }
}

export const guardExpenseCreation = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.expense?.canCreateExpense
  return canAccess || { name: 'Home' }
}

export const guardExpenseSettings = () => {
  const userStore = useUserStore()
  const user = userStore.user
  const canAccess = user.profile.modules.expense?.canEditSettings
  return canAccess || { name: 'Home' }
}

// export const guardCreation360 = () => {
//   const userStore = useUserStore()
//   const user = userStore.user
//   const canAccess = user.profile.modules.evaluation?.create360
//   return canAccess || { name: 'Home' }
// }
