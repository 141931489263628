import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IPaginated, IResource } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'resources'

// // router.post('/:resourceId/skill/:skillId', auth.isAuth(), skillController.addSkill);
// const addSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skill: {skillId: string, value: any}) => {
//   return axios({
//     method: EMethod.post,
//     url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill`,
//     data: skill
//   })
// }

// fusion of addSkill and updateSkill
// router.put('/:resourceId/skill/:skillId', auth.isAuth(), skillController.updateSkill);
const updateSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skill : { skillId: string, value: any }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/`,
    data: skill,
  })
}
// router.delete('/:resourceId/skill/:skillId', auth.isAuth(), skillController.deleteSkill);
const deleteSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}`,
  })
}



// // router.post('/:resourceId/skill/:skillId/tag', auth.isAuth(), skillController.tagSkill);
// const tagSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string) => {
//   return axios({
//     method: EMethod.post,
//     url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}/tag`,
//   })
// }
// // router.delete('/:resourceId/skill/:skillId/tag', auth.isAuth(), skillController.untagSkill);
// const untagSkill = (axios: IFactoryFunctionRequest<IResource, any >) => (resourceId: string, skillId: string) => {
//   return axios({
//     method: EMethod.delete,
//     url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/skill/${skillId}/tag`,
//   })
// }



export default (axios: AxiosInstance) => ({
  // addSkill: addSkill(useAxios(axios)),
  updateSkill: updateSkill(useAxios(axios)),
  deleteSkill: deleteSkill(useAxios(axios)),
})
